import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import GraphQLErrorList from '../components/graphql-error-list';
import SEO from '../components/seo';
import Layout from '../containers/layout';
import RenderSections from '../components/RenderSections';

export const query = graphql`
  query IndexPageQuery {
    sanityPage(slug: { current: { eq: "/" } }) {
      ...BasicPage
    }
  }
`;

const IndexPage = props => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).sanityPage;

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.',
    );
  }

  return (
    <Layout>
      <SEO
        title={site.title}
        description={site.description}
        keywords={site.keywords}
      />
      <Helmet>
        <link rel="icon" type="image/svg+xml" href="/icons/logo.svg" />
      </Helmet>
      <RenderSections sections={site.content} />
    </Layout>
  );
};

export default IndexPage;
